import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Strict Chin-ups (weighted if possible) 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`5-Bench Press (135/85)`}</p>
    <p>{`15-KBS (53/35)`}</p>
    <p>{`10-Bench Press`}</p>
    <p>{`25-KBS`}</p>
    <p>{`15-Bench Press`}</p>
    <p>{`35-KBS`}</p>
    <p>{`20-Bench Press`}</p>
    <p>{`45-KBS`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      